'use client';
import React, { useEffect } from 'react';

import { ROUTERS } from '@/constant';
import { useLang } from '@/hooks';
import { classNames } from '@/utils';

import { SmartLink } from '../../..';
import { ADMIN, EXPERT, MODERATOR } from '../../Header/header.constant';
import LoginModal from '../../LoginModal';
import { useAuthStore } from '../../LoginModal/Auth.store';

import confirm from 'antd/es/modal/confirm';
import Aos from 'aos';
import { includes } from 'ramda';

export default function AuthButton({ className }: { className?: string }) {
	const { isShowAuthModal, setShowAuthModal, getProfile, profile, logout } =
		useAuthStore((state) => state);

	useEffect(() => {
		Aos.init({
			delay: 100,
			once: true,
			duration: 700,
		});
		getProfile();
	}, []);

	const { t, lang } = useLang();
	// Go to admin page
	const checkRoles = includes(profile?.roles?.[0], [
		ADMIN,
		EXPERT,
		MODERATOR,
	]);

	const handleLogout = () => {
		confirm({
			title: `${t('header.confirm')}`,
			okText: t('header.yes'),
			okType: 'danger',
			cancelText: t('header.no'),
			onOk() {
				logout();
			},
			onCancel() {},
		});
	};

	if (!profile)
		return (
			<>
				{isShowAuthModal && <LoginModal />}

				<button
					className={classNames(
						'text-left bg-transparent',
						className
					)}
					onClick={() => setShowAuthModal(true)}
				>
					{t('header.sign-in')}
				</button>
			</>
		);

	return (
		<>
			{checkRoles && (
				<SmartLink
					href={
						profile?.roles?.[0] === EXPERT ||
						profile?.roles?.[0] === MODERATOR
							? ROUTERS.ADMIN_EXPERT_BLOG
							: ROUTERS.ADMIN_DASHBOARD
					}
				>
					<span>{t('header.admin')}</span>
				</SmartLink>
			)}
			<button onClick={handleLogout} className="text-left bg-transparent">
				{t('header.logout')}
			</button>
		</>
	);
}
