import { memo } from 'react';
import Image, { ImageProps } from 'next/image';

import { classNames } from '@/utils';

import { isNilOrEmpty } from 'ramda-adjunct';

export interface StaticImageData {
	src: string;
	height: number;
	width: number;
	blurDataURL?: string;
	blurWidth?: number;
	blurHeight?: number;
}
interface StaticRequire {
	default: StaticImageData;
}
declare type StaticImport = StaticRequire | StaticImageData;

interface IBackgroundImageProps extends Omit<ImageProps, 'alt' | 'src'> {
	alt?: string;
	src: string | StaticImport | undefined;
}

const BackgroundImage = ({
	src,
	className,
	...rest
}: IBackgroundImageProps) => {
	if (isNilOrEmpty(src))
		return (
			<div
				className={classNames(
					'object-center object-cover bg-slate-200 absolute inset-0',
					className
				)}
			/>
		);

	return (
		<Image
			fill
			src={src}
			alt=""
			// onError={handleError}
			className={classNames('object-center object-cover', className)}
			sizes="(max-width: 768px) 100vw,(max-width: 1200px) 50vw,33vw"
			{...(rest as any)}
		/>
	);
};

export default memo(BackgroundImage);
