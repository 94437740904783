'use client';
import React, { useRef, useState } from 'react';
import { IoCloseOutline, IoReorderFourOutline } from 'react-icons/io5';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

import { NextImage, SmartLink } from '@/app/_components/general';
import { ROUTERS } from '@/constant';
import { useLang } from '@/hooks';
import { useChangeLocale } from '@/locales/client';

import { HEADERS } from './header.constant';

const HeaderMobile = dynamic(() => import('./header.mobile'), {
	ssr: false,
	loading: () => <div className=""></div>,
});

export default function Header() {
	const { t, lang } = useLang();
	const pathname = usePathname();
	const changeLocale = useChangeLocale();

	const [isMenu, setMenu] = useState(false);

	const selectorRef = useRef<any>();

	const onOpenMenu = () => {
		setMenu(!isMenu);
	};

	return (
		<>
			<div
				className={`bg-white z-30 h-[50px] md:h-[80px] shadow sticky top-0`}
			>
				<div className="flex items-center w-full h-full lg:justify-between container px-[2rem] md:px-0">
					<SmartLink
						href={'/'}
						className={
							'flex md:min-w-[170px] max-w-[180px] max-sm:w-[140px] bg-center bg-cover object-center max-sm:h-auto -ml-1'
						}
					>
						<NextImage
							src={require('@/app/assets/image/logo/logoc4c.png')}
							alt={'LogoCode4Change'}
						/>
					</SmartLink>
					<div className="flex-row justify-end flex-1 hidden lg:flex">
						{HEADERS.map((item, index) => (
							<div
								key={index}
								className={`px-4 pt-8 md:mt-0 md:py-2 ${
									pathname === item.route &&
									'md:border-b-[2px] border-blue-9'
								} hover:text-blue-9 transition-all duration-500 `}
							>
								<SmartLink href={item?.route}>
									<p className="text-sm">{t(item.label)}</p>
								</SmartLink>
							</div>
						))}
					</div>
					<div className={'relative ml-2 lg:block hidden'}>
						<button
							ref={selectorRef}
							onClick={changeLocale}
							className={
								'p-1 rounded-md bg-transparent hover:bg-blue-7/30 duration-300'
							}
							title="language"
						>
							<NextImage
								src={
									lang === 'en'
										? require('./vietnam.png')
										: require('./english.png')
								}
								placeholder="blur"
								className="object-cover bg-center bg-cover"
								priority
							/>
						</button>
					</div>
					<div className={'row max-lg:flex-1 justify-end'}>
						<button
							onClick={() => onOpenMenu()}
							className="center h-[50px] w-[50px] bg-transparent lg:hidden outline-none"
						>
							<IoCloseOutline
								className={`absolute text-[26px] duration-200 ${
									isMenu ? 'opacity-100' : 'opacity-0'
								}`}
							/>
							<IoReorderFourOutline
								className={`absolute text-[24px] duration-200 ${
									isMenu ? 'opacity-0' : 'opacity-100'
								}`}
							/>
						</button>
					</div>

					<SmartLink
						href={ROUTERS.CONTACT}
						className={
							'border border-blue-2 hover:bg-white hover:text-blue-2 text-sm py-1.5 px-3 rounded-full bg-blue-2 text-white hover:shadow-card duration-300 ml-4 lg:block hidden'
						}
					>
						{t('header.contact')}
					</SmartLink>
				</div>
			</div>
			<HeaderMobile
				isMenu={isMenu}
				setMenu={setMenu}
				pathname={pathname}
			/>
		</>
	);
}
