'use client';
import React, { memo, useCallback } from 'react';
import { IoFilterOutline } from 'react-icons/io5';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { ORDERS } from '@/constant';
import { useI18n } from '@/locales/client';

const Sort = () => {
	const t: any = useI18n();
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { push } = useRouter();

	const order = searchParams.get('order')?.toString();
	const handleSort = useCallback(
		(item: any) => {
			const params = new URLSearchParams(searchParams);
			params.set('order', item.value);
			params.set('page', '1');
			push(`${pathname}?${params.toString()}`);
		},
		[pathname]
	);

	return (
		<div className="relative justify-center mb-6 bg-white rounded-md shadow group/sort row h-9 w-9">
			<IoFilterOutline className={'text-blue-2 text-[22px]'} />

			<div
				className={
					'divide-y divide-gray-2 flex flex-col absolute z-10 shadow right-10 rounded-md bg-white border border-gray-100 group-hover/sort:opacity-100 opacity-0 duration-300 min-w-max overflow-hidden'
				}
			>
				{ORDERS.map((item: any) => (
					<button
						onClick={() => handleSort(item)}
						key={item.value}
						className={`${
							order === item.value
								? 'text-blue-2'
								: 'text-gray-9 hover:text-blue-2'
						} text-[13px] font-medium px-2 py-1 bg-white`}
					>
						{t(item.label)}
					</button>
				))}
			</div>
		</div>
	);
};

export default memo(Sort);
