import React, { memo } from 'react';
import Image, { ImageProps } from 'next/legacy/image';

import { insertObjectIf } from '@/utils';

interface ILegacyImageProps extends Omit<ImageProps, 'alt' | 'src'> {
	fill?: boolean;
	src: string | any | undefined;
	alt?: string;
}

const LegacyImage = ({ src, fill, alt = '', ...rest }: ILegacyImageProps) => (
	<>
		<Image
			src={src}
			alt={alt}
			{...insertObjectIf(fill, {
				layout: 'fill',
				objectFit: 'cover',
				objectPosition: 'center',
			})}
			{...insertObjectIf(rest.blurDataURL, {
				placeholder: 'blur',
			})}
			{...rest}
		/>
	</>
);

export default memo(LegacyImage);
