import React, { memo } from 'react';
import dynamic from 'next/dynamic';

const TextEditor = dynamic(
	() => import('@/app/_components/form/TextEditor/ReadOnly'),
	{
		ssr: false,
		loading: () => (
			<div className="flex items-center justify-center w-full h-96"></div>
		),
	}
);
export const ContentSlug = memo(
	({ data }: { data: { title: string | any; content: string | any } }) => {
		return (
			<>
				<h1 className="mt-3 text-3xl font-semibold text-blue-2">
					{data?.title}
				</h1>
				<TextEditor value={data?.content} className={'text-gray-7 '} />
			</>
		);
	}
);
