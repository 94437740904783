'use client';
import React, { memo, useState } from 'react';
import {
	IoBarcode,
	IoCaretForwardOutline,
	IoPlayCircleOutline,
} from 'react-icons/io5';

import Icon from '@/app/[locale]/course/[slug]/lesson/ExamIcon';
import { ILesson } from '@/services';

interface IProps {
	item: any;
	index: number;
	onLearn: any;
	formQuestion: any;
	lesson: any;
}

export const ListLessons = memo(
	({ item, lesson, index, onLearn, formQuestion }: IProps) => {
		const [showMore, setShowMore] = useState(true);

		return (
			<>
				<div
					onClick={() => setShowMore(!showMore)}
					className={
						'px-5 row items-center cursor-pointer py-3 bg-blue-2'
					}
				>
					<p
						title={item?.name}
						className={
							'text-[17px] font-semibold text-white truncate flex-1 w-10 mr-2'
						}
					>
						{index + 1}. {item?.name}
					</p>
					<IoCaretForwardOutline
						className={`text-[18px] text-white duration-300 ${
							showMore ? 'rotate-90' : 'rotate-0'
						}`}
					/>
				</div>
				<div
					className={`overflow-hidden`}
					style={{ height: showMore ? 'auto' : 0 }}
				>
					<div className={'divide-y divide-white'}>
						{item?.lessons?.map((subItem: ILesson) => (
							<div
								onClick={() => onLearn(subItem, item)}
								key={subItem?.id}
								className={`w-full py-4 px-8 ${
									lesson?.id === subItem?.id &&
									!formQuestion.active
										? 'text-blue-2'
										: 'hover:text-blue-2 hover:bg-blue-2/10 text-gray-7'
								} bg-zinc-100/40 row cursor-pointer`}
							>
								{lesson?.id === subItem?.id &&
								!formQuestion.active ? (
									<IoBarcode />
								) : (
									<IoPlayCircleOutline />
								)}
								<h2
									className={
										'flex-1 text-sm font-medium ml-2 '
									}
								>
									{subItem?.title}{' '}
									{subItem?.videoUrl && `(12:00)`}
								</h2>
							</div>
						))}
						{item.id === item?.questions?.[0]?.programId && (
							<div
								onClick={() => onLearn({}, item)}
								className={`row text-sm w-full py-4 px-8 cursor-pointer ${
									formQuestion.active === item?.id &&
									'text-blue-2'
								} hover:bg-blue-2/10 text-gray-7 duration-200`}
							>
								<Icon />
								<h2 className={'text-sm ml-2 '}>
									Kiểm tra kiến thức
								</h2>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
);
