'use client';
import React, { memo } from 'react';
import { useParams } from 'next/navigation';

import SmartLink from '@/app/_components/general/SmartLink';
import Icon from '@/app/[locale]/course/[slug]/lesson/ExamIcon';
import { ROUTERS } from '@/constant';

import CText from '../CText';

export const ExamButton = memo(({ examId }: { examId?: number }) => {
	const { slug } = useParams();

	const targetPath =
		ROUTERS.LESSON_DETAIL.replace('[slug]', slug as string) +
		(examId ? `?exam=${examId}` : '');

	return (
		<div
			className={
				'row w-full justify-between h-[44px] md:px-5 px-3 text-gray-7 hover:bg-blue-2/30 bg-zinc-300/40 duration-300'
			}
		>
			<div className={'row'}>
				<Icon />
				<CText className={'text-sm ml-2 '} content="btn.test" />
			</div>
			<SmartLink href={targetPath}>
				<button className="row bg-red-3 rounded-full text-xs text-white px-1 py-1">
					{'Bắt đầu'}
				</button>
			</SmartLink>
		</div>
	);
});
