function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      version="1.1"
      viewBox="0 0 682.667 682.667"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="clipPath1958" clipPathUnits="userSpaceOnUse">
          <path d="M0 512h512V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
        <g>
          <g clipPath="url(#clipPath1958)">
            <g transform="translate(186.27 394)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0h-14.518c-6.791 0-12.296 5.505-12.296 12.296v39.408c0 6.791 5.505 12.296 12.296 12.296H0"
              ></path>
            </g>
            <g transform="translate(159.457 426)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0h22.993"
              ></path>
            </g>
            <g transform="translate(249.593 458)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0h-11.512c-8.837 0-16-7.163-16-16v0c0-8.837 7.163-16 16-16h1.167c8.836 0 16-7.163 16-16v0c0-8.837-7.164-16-16-16h-17.167"
              ></path>
            </g>
            <g transform="translate(112.748 458)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0v-64"
              ></path>
            </g>
            <g transform="translate(96.248 458)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0h33"
              ></path>
            </g>
            <g transform="translate(305.248 458)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0v-64"
              ></path>
            </g>
            <g transform="translate(288.748 458)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0h33"
              ></path>
            </g>
            <g transform="translate(400.998 225)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0v-191c0-13.255-10.745-24-24-24h-336c-13.255 0-24 10.745-24 24v444c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V119"
              ></path>
            </g>
            <path
              fill="none"
              stroke="#000"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeOpacity="1"
              strokeWidth="20"
              d="M120.456 294h-40v40h40z"
            ></path>
            <g transform="translate(333.623 294)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0v0"
              ></path>
            </g>
            <g transform="translate(160.123 294)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0h133.5"
              ></path>
            </g>
            <path
              fill="none"
              stroke="#000"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeOpacity="1"
              strokeWidth="20"
              d="M120.456 190h-40v40h40z"
            ></path>
            <g transform="translate(160.123 190)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0h153.588"
              ></path>
            </g>
            <path
              fill="none"
              stroke="#000"
              strokeDasharray="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeOpacity="1"
              strokeWidth="20"
              d="M120.456 86h-40v40h40z"
            ></path>
            <g transform="translate(160.123 86)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0h85.875"
              ></path>
            </g>
            <g transform="translate(481.621 368.83)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0l-137.34-237.88-58.284-44.95 9.787 72.95L-38.872 44.671C-31.14 58.063-14.016 62.652-.624 54.92v0c13.392-7.732 17.981-24.857 10.249-38.249L0 0"
              ></path>
            </g>
            <g transform="translate(341.44 132.59)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0l-44.501 25.692"
              ></path>
            </g>
            <g transform="translate(481.621 368.83)">
              <path
                fill="none"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="20"
                d="M0 0l-48.497 28"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
