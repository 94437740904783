import React, { memo } from 'react';
import Script from 'next/script';

const Analytic: React.FC<{ id: string }> = ({ id = '' }) => {
	if (process.env.NODE_ENV === 'development') return null;

	return (
		<>
			<Script
				strategy="lazyOnload"
				src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
			/>
			<Script strategy="lazyOnload" id="my-app-analytic">
				{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${id}', {
          page_path: window.location.pathname,
          });
          `}
			</Script>
		</>
	);
};

export default memo(Analytic);
