'use client';
import { memo, useEffect, useRef } from 'react';

import { isDev, ServiceApi } from '@/services';

const Viewer = ({
	courseId,
	blogId,
}: {
	courseId?: number;
	blogId?: number;
}) => {
	let timer: any = useRef();
	useEffect(() => {
		if (isDev) return;

		timer.current = setTimeout(() => {
			if (courseId) {
				ServiceApi.viewCourse(Number(courseId));
			}
			if (blogId) {
				ServiceApi.viewBlog(Number(blogId));
			}
		}, 10000);

		return () => clearTimeout(timer.current);
	}, []);

	return null;
};

export default memo(Viewer);
