'use client';
import React from 'react';

import { useLang } from '@/hooks';
import { isSuccess, ServiceApi } from '@/services';
import emailjs from '@emailjs/browser';

import { message } from 'antd';
import { getCookie, setCookie } from 'cookies-next';
import moment from 'moment/moment';

const FooterForm: React.FC = () => {
	const { t } = useLang();

	const onAddEmail = async (event: any) => {
		event.preventDefault();
		const data = {
			email: event.target.elements.email.value,
			name: event.target.elements.name.value,
			phone: event.target.elements.phone.value,
			message: event.target.elements.message.value,
		};

		const res: any = await ServiceApi.createContact({
			...data,
			branchId: 1,
			createdAt: moment().format('hh:mm DD.MM.YYYY'),
		});

		const lastTimeSubscribe = getCookie('lastTimeSubscribe');
		const now = moment().unix();

		if (!lastTimeSubscribe || now - Number(lastTimeSubscribe) > 60 * 5) {
			setCookie('lastTimeSubscribe', now.toString());

			if (isSuccess(res) && res.data?.id) {
				message.success(t('contact.message_success'));
				try {
					emailjs.send(
						process.env.SERVICE_ID!,
						process.env.TEMPLATE_ID!,
						data,
						process.env.PUBLIC_KEY!
					);
				} catch (e: any) {}
			} else {
				message.error(
					res.data?.error?.detail || t('contact.message_error')
				);
			}
			return;
		}
	};

	return (
		<form
			onSubmit={onAddEmail}
			className={
				'flex flex-col shadow-default bg-white lg:-mt-20 mb-10 p-6 md:min-w-[350px] rounded-default'
			}
		>
			<p
				className={
					'md:text-[24px] text-[20px] text-gray-7 font-bold whitespace-nowrap'
				}
			>
				{t('footer.contact.title')}
			</p>
			<p className={'max-md:text-[15px] text-gray-5 font-medium mt-1'}>
				{t('footer.contact.description')}
			</p>
			<div className={'flex flex-col space-y-4 mt-3'}>
				<input
					type="text"
					name={'name'}
					id="name"
					placeholder={t('footer.contact.input.1')}
					className="w-full text-sm h-[44px] px-3 text-gray-7 bg-white outline-none rounded-sm ring-1 ring-gray-3"
					required
				/>
				<input
					type="email"
					name={'email'}
					id="email"
					placeholder={t('footer.contact.input.2')}
					className="w-full text-sm h-[44px] px-3 text-gray-7 bg-white outline-none rounded-sm ring-1 ring-gray-3"
					required
				/>
				<input
					type="phone"
					name={'phone'}
					id={'phone'}
					placeholder={t('footer.contact.input.3')}
					className="w-full text-sm h-[44px] px-3 text-gray-7 bg-white outline-none rounded-sm ring-1 ring-gray-3"
					required
				/>
				<textarea
					rows={4}
					name={'message'}
					id={'message'}
					placeholder={t('footer.contact.input.4')}
					className={
						'px-3 py-2 text-sm text-gray-7 bg-white outline-none rounded-sm ring-1 ring-gray-3 resize-none'
					}
				/>
				<button
					title={t('footer.contact.btn')}
					className={
						'bg-blue-2 rounded-sm py-2 text-white font-medium'
					}
				>
					{t('footer.contact.btn')}
				</button>
			</div>
		</form>
	);
};

export default FooterForm;
