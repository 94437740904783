'use client';
import React, { memo, useState } from 'react';

import { useLang } from '@/hooks';

import { useAuthStore } from './Auth.store';
import ForgotPassword from './ForgotPassword';
import LoginView from './Login';
import RegisterView from './Register';

import { Modal } from 'antd';

const FORM_KEY = { login: 1, register: 2, resetPassword: 3 };
const LoginModal = () => {
	const { t } = useLang();
	const { isShowAuthModal, setShowAuthModal } = useAuthStore(
		(state) => state
	);
	const [goToForm, setGoToForm] = useState(FORM_KEY.login);

	const handleCancel = () => setShowAuthModal(false);
	const handleOk = () => setShowAuthModal(false);

	return (
		<Modal
			open={isShowAuthModal}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={false}
		>
			<div>
				<span className={'text-xl font-bold text-center'}>{`${
					goToForm === FORM_KEY.register
						? t('login.title.sign.up')
						: t('login.title.sign.in')
				} `}</span>
				{goToForm === FORM_KEY.login && (
					<LoginView
						onClose={handleCancel}
						onRegister={() => setGoToForm(FORM_KEY.register)}
						onResetPassword={() =>
							setGoToForm(FORM_KEY.resetPassword)
						}
					/>
				)}
				{goToForm === FORM_KEY.register && (
					<RegisterView
						onClose={handleCancel}
						onLogin={() => setGoToForm(FORM_KEY.login)}
						onResetPassword={() =>
							setGoToForm(FORM_KEY.resetPassword)
						}
					/>
				)}
				{goToForm === FORM_KEY.resetPassword && (
					<ForgotPassword
						onClose={handleCancel}
						onLogin={() => setGoToForm(FORM_KEY.login)}
						onRegister={() => setGoToForm(FORM_KEY.register)}
					/>
				)}
			</div>
		</Modal>
	);
};
export default memo(LoginModal);
