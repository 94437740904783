import React, {memo} from 'react';
import {IoChevronForward} from "react-icons/io5";

interface IProps {
    previous?: any;
    next?: any;
    page?: number;
    pageSize?: number;
    itemsCount?: number;
    totalCount?: number;
    autoHide?: boolean;
}
const Pagination = ({previous, next, page = 0, pageSize = 0, itemsCount = 0, totalCount = 0, autoHide = false}: IProps) => {
    const _nextDisabled = (itemsCount < pageSize) || ((page + 1) * pageSize >= totalCount);
    const _previousDisabled = page <= 0;

    const _next = () => !_nextDisabled && next && next();
    const _previous = () => !_previousDisabled && previous && previous();

    return (
        (!autoHide || (totalCount && totalCount > pageSize)) ?
            <div className='flex justify-center items-center my-1.5'>
                {totalCount ? <span
                        className='text-[12px] mr-5'>{`Show ${Math.min(page * pageSize + 1, totalCount)}-${Math.min(page * pageSize + itemsCount, totalCount)} of ${totalCount} results`}</span> :
                    <span/>}

                <div className='flex justify-between w-[128px]'>
                    <button onClick={_previous}
                            className={`rotate-180 bg-white w-[36px] h-[36px] rounded-full flex items-center justify-center ring-1 ring-gray ${_previousDisabled ? 'cursor-not-allowed opacity-50' : ''}`}>
                        <IoChevronForward/>
                    </button>

                    <button className='w-[36px] h-[36px] rounded-full flex items-center justify-center bg-white ring-1 ring-gray'>
                        <span className='text-sm opacity-50'>{(page + 1).toString()}</span>
                    </button>

                    <button onClick={_next}
                            className={`w-[36px] h-[36px] rounded-full flex items-center justify-center bg-white ring-1 ring-gray ${_nextDisabled ? 'cursor-not-allowed opacity-50' : ''}`}>
                        <IoChevronForward/>
                    </button>
                </div>
            </div>
            :
            null
    );
};

export default memo(Pagination);
