import React, { memo, useState } from 'react';

import { useLang } from '@/hooks';
import { registerApi } from '@/services/Api/account.api';

import { useAuthStore } from '../Auth.store';

import { Button, Form, Input } from 'antd';

const FormItem = Form.Item;
const InputPassword = Input.Password;

const RegisterForm = ({
	onClose,
	onLogin,
	onResetPassword,
}: {
	onClose: () => void;
	onLogin: () => void;
	onResetPassword: () => void;
}) => {
	const { t } = useLang();

	const getProfile = useAuthStore((state) => state.getProfile);
	const [loading, setLoading] = useState(false);

	const onFinish = async (values: any) => {
		setLoading(true);
		try {
			const valPassword = values.password === values.confirmPassword;

			if (valPassword) {
				const { email, password, lastName, firstName } = values;

				await registerApi(email, password, firstName, lastName);
				getProfile();
				onClose?.();
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<Form
			name={'control-ref'}
			initialValues={{
				firstName: '',
				lastName: '',
				email: '',
				password: '',
				confirmPassword: '',
			}}
			size={'large'}
			layout="horizontal"
			className={'mt-[30px] w-full'}
			onFinish={onFinish}
		>
			<FormItem
				name="firstName"
				rules={[
					{
						required: true,
						message: 'Please input your first name!',
					},
				]}
				hasFeedback
			>
				<Input placeholder="Họ" />
			</FormItem>
			<FormItem
				name="lastName"
				rules={[
					{
						required: true,
						message: 'Please input your last name!',
					},
				]}
				hasFeedback
			>
				<Input placeholder="Tên" />
			</FormItem>
			<FormItem
				name="email"
				rules={[
					{ required: true, message: 'Please input your email!' },
				]}
				hasFeedback
			>
				<Input placeholder="Email" />
			</FormItem>
			<FormItem
				name="password"
				rules={[
					{
						required: true,
						message: 'Please input your password!',
					},
				]}
				hasFeedback
			>
				<InputPassword placeholder="Password" />
			</FormItem>
			<FormItem
				name="confirmPassword"
				rules={[
					{
						required: true,
						message: 'Please input your confirm password!',
					},
				]}
				hasFeedback
			>
				<InputPassword placeholder="Confirm Password" />
			</FormItem>
			<div className={'flex flex-col mb-6 space-y-6'}>
				<span
					onClick={onResetPassword}
					className={
						'text-center underline text-green-500 text-base cursor-pointer'
					}
				>
					{t('login.forgot.password')}
				</span>
				<span
					onClick={onLogin}
					className={
						'text-center font-light text-base cursor-pointer'
					}
				>
					<span className={'underline text-green-500 font-normal'}>
						{t('header.sign-in')}
					</span>
				</span>
			</div>
			<FormItem className={'mb-0'}>
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className={'w-full'}
				>
					{t('login.register')}
				</Button>
			</FormItem>
		</Form>
	);
};
export default memo(RegisterForm);
