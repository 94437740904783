import { memo } from 'react';

interface IProps {
	placeholder?: string;
	className?: string;
	value?: string;
	onChangeText?: any;
	rows?: number;
}

const QuestionForm = memo(
	({ placeholder, className, onChangeText, rows = 5, value }: IProps) => {
		return (
			<div
				className={`flex shadow-default p-4 bg-gray-1 rounded-default ${className}`}
			>
				<textarea
					className={`flex-1 border-none outline-none bg-transparent text-sm resize-none`}
					required
					placeholder={placeholder}
					rows={rows}
					onChange={onChangeText}
					value={value}
				/>
			</div>
		);
	}
);

export default QuestionForm;
