import React, { memo, useState } from 'react';

import { useLang } from '@/hooks';
import { isSuccess, ServiceApi } from '@/services';

import { Button, Form, Input, message } from 'antd';

const FormItem = Form.Item;
const InputPassword = Input.Password;

const ForgotPassword = ({
	onRegister,
	onClose,
	onLogin,
}: {
	onLogin: () => void;
	onRegister: () => void;
	onClose: () => void;
}) => {
	const { t } = useLang();
	const [isInitResetPassword, setIsInitPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [resetEmail, setEmail] = useState('');

	const onFinish = async (values: any) => {
		setLoading(true);
		try {
			const { email, resetKey, password } = values;
			setEmail(email);
			if (!isInitResetPassword) {
				const res: any = await ServiceApi.initResetPassword({ email });

				if (isSuccess(res)) {
					setIsInitPassword(true);
					message.success(
						'Please check your email to get the reset password key'
					);
				} else {
					message.error(res.data?.error?.message);
				}
			} else {
				const res: any = await ServiceApi.finishResetPassword({
					resetKey,
					password,
				});
				if (isSuccess(res)) {
					setIsInitPassword(true);
					message.success('You reset the password successful');
					onLogin();
				} else {
					message.error(res.data?.error?.message);
				}
			}
		} finally {
			setLoading(false);
		}
	};

	const onResentResetKey = async () => {
		const res = await ServiceApi.initResetPassword({ email: resetEmail });

		if (isSuccess(res)) {
			message.success(
				'Please check your email to get the reset password key'
			);
		}
	};

	return (
		<Form
			name={'control-ref'}
			initialValues={{
				email: '',
				password: '',
			}}
			size={'large'}
			layout="horizontal"
			className={'mt-[30px] w-full'}
			onFinish={onFinish}
		>
			<FormItem
				name="email"
				rules={[{ required: true, message: t('login.rules.email') }]}
				hasFeedback
			>
				<Input placeholder="Email" autoComplete="off" />
			</FormItem>

			{isInitResetPassword && (
				<>
					<FormItem
						name="resetKey"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="Reset Key" />
					</FormItem>
					<FormItem
						name="password"
						rules={[
							{
								required: true,
							},
							{
								min: 8,
								message:
									'Password must be minimum 8 characters.',
							},
						]}
						hasFeedback
					>
						<InputPassword
							placeholder="Password"
							autoComplete="new-password"
						/>
					</FormItem>
				</>
			)}
			{isInitResetPassword && (
				<div className="flex justify-end">
					<span
						onClick={onResentResetKey}
						className={'text-center cursor-pointer'}
					>
						{'Gửi lại mã'}
					</span>
				</div>
			)}
			<div className={'flex flex-col mb-6 space-y-6'}>
				<span
					onClick={onLogin}
					className={
						'text-center underline text-green-500 text-base cursor-pointer'
					}
				>
					{t('header.sign-in')}
				</span>
				<span
					onClick={onRegister}
					className={
						'text-center font-light text-base cursor-pointer'
					}
				>
					{t('login.not.account')}{' '}
					<span className={'underline text-green-500 font-normal'}>
						{t('login.register')}
					</span>
				</span>
			</div>
			<FormItem className={'mb-0 center'}>
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className={'min-w-[100px]'}
				>
					{!isInitResetPassword ? 'Gửi mã xác thực' : 'Đổi mật khẩu'}
				</Button>
			</FormItem>
		</Form>
	);
};

export default memo(ForgotPassword);
