import React, { memo } from 'react';

import { classNames } from '@/utils';

interface IButtonV2Props {
	className?: string;
	name?: string;
	lightClassName?: string;
}

const ButtonV2 = ({
	className,
	name = 'Liên hệ',
	lightClassName,
}: IButtonV2Props) => {
	return (
		<div
			className={classNames(
				'h-12 min-w-[170px] border-white border center relative font-medium text-[12px] tracking-[2px] uppercase hover:bg-white hover:text-gray-9 duration-1000 cursor-pointer',
				className
			)}
		>
			{name}
			<div
				className={classNames(
					'absolute w-[22px] right-0 border-t-[1px] translate-x-[50%]',
					lightClassName
				)}
			/>
			<div
				className={classNames(
					'absolute w-[22px] left-0 border-t-[1px] translate-x-[-50%]',
					lightClassName
				)}
			/>
		</div>
	);
};

export default memo(ButtonV2);
