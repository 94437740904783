import React, { memo } from 'react';

import SmartLink from '@/app/_components/general/SmartLink';
import { ROUTERS } from '@/constant';

export const StudyButton = memo(
	({
		children,
		lessonId,
		slug,
	}: {
		children?: any;
		lessonId?: number;
		slug: string;
	}) => {
		const targetPath =
			ROUTERS.LESSON_DETAIL.replace('[slug]', slug as any) +
			(lessonId ? `?lessonId=${lessonId}` : '');

		return (
			<SmartLink href={targetPath}>
				<button className="row !bg-red-3 text-white font-medium rounded-full px-3 py-1 text-sm">
					{children}
				</button>
			</SmartLink>
		);
	}
);
