import React, { memo } from 'react';
import Image, { ImageProps } from 'next/image';

import { classNames } from '@/utils';

interface INextImage extends Omit<ImageProps, 'alt' | 'src'> {
	alt?: string;
	src: string | undefined | any;
}
const NextImage: React.FC<INextImage> = ({
	src,
	alt = '',
	className,
	...props
}) => {
	// const [src, setSrc] = useState(original);

	// const handleError = useCallback(() => {
	// 	// setSrc(Images.placeDefault);
	// }, []);

	// useEffect(() => {
	// 	setSrc(original);
	// }, [original]);

	// if (isNilOrEmpty(src)) {
	// 	return (
	// 		<Image
	// 			src={Images.placeDefault}
	// 			alt={alt}
	// 			className={classNames(
	// 				!props?.width && 'w-full h-auto',
	// 				className
	// 			)}
	// 			{...insertObjectIf(isUseBlurEffect, {
	// 				placeholder: 'blur',
	// 			})}
	// 			{...props}
	// 			sizes="(max-width: 768px) 100vw,(max-width: 1200px) 50vw,33vw"
	// 		/>
	// 	);
	// }

	return (
		<Image
			src={src}
			alt={alt}
			className={classNames(!props?.width && 'w-full h-auto', className)}
			quality={100}
			// onError={handleError}
			{...(props as any)}
			sizes="(max-width: 768px) 100vw,(max-width: 1200px) 50vw,33vw"
		/>
	);
};

export default memo(NextImage);
