import { isSuccess, IUser, ServiceApi, setApiAuthorization } from '@/services';
import {
	deleteCookieAfterLogout,
	getAccessToken,
	setAuthCookie,
} from '@/utils';

import { message } from 'antd';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type AuthStoreType = {
	loading: boolean;
	isLogged: boolean;
	isShowAuthModal: boolean;
	profile: IUser | null;
	loginSuccess: () => void;
	loginGoogle: (payload: any) => void;
	logoutSuccess: () => void;
	setShowAuthModal: (show: boolean) => void;
	getProfile: () => void;
	logout: () => void;
	setLoading: (newStatus: boolean) => void;
};

export const useAuthStore = create(
	persist<AuthStoreType>(
		(set, get) => ({
			loading: false,
			isLogged: false,
			isShowAuthModal: false,
			profile: null,
			loginSuccess: () => set({ isLogged: true, isShowAuthModal: false }),
			logoutSuccess: () => set({ isLogged: false }),
			setLoading: (newStatus: boolean) => set({ loading: newStatus }),
			setShowAuthModal: (show: boolean) => set({ isShowAuthModal: show }),
			getProfile: async () => {
				const token = getAccessToken();
				if (token) {
					setApiAuthorization(token);
					const res = await ServiceApi.getUserMe();
					if (isSuccess(res)) {
						set({ profile: res?.data, isLogged: true });
						return;
					}
					setApiAuthorization(null);
					set({ isLogged: false, profile: null });
				}
				set({ isLogged: false, profile: null });
			},
			loginGoogle: async (payload: any) => {
				const res = await ServiceApi.loginGoogle(payload);
				if (isSuccess(res)) {
					set({
						isLogged: true,
						isShowAuthModal: false,
						loading: false,
					});
					if (res?.data?.token) {
						setApiAuthorization(res?.data?.token);
						setAuthCookie({ token: res.data.token });

						const res2 = await ServiceApi.getUserMe();
						if (isSuccess(res2)) {
							set({ profile: res2?.data, isLogged: true });
							return;
						}
						setApiAuthorization(null);
						set({ isLogged: false, profile: null });
						return;
					}
				}
				set({ loading: false });
			},
			logout: () => {
				deleteCookieAfterLogout();
				set({ isLogged: false, profile: null });
				message.success('Bạn đã đăng xuất thành công');
			},
		}),
		{
			name: 'auth-storage', // unique name
			storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
		}
	)
);
