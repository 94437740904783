import { isSuccess, ServiceApi, setApiAuthorization } from '@/services';
import { setAuthCookie } from '@/utils';

import { message } from 'antd';

export const loginApi = async (email: string, password: string) => {
	const res = await ServiceApi.login({ email, password });
	if (isSuccess(res)) {
		message.success('Bạn đã đăng nhập thành công!');
		setAuthCookie(res.data!);
		setApiAuthorization(res?.data?.token);
		return true;
	}
	message.error('Email hoặc mật khẩu không đúng!');

	return false;
};

export const registerApi = async (
	email: string,
	password: string,
	firstName: string,
	lastName: string
) => {
	const res: any = await ServiceApi.register({
		email,
		firstName,
		lastName,
		password,
	});

	if (isSuccess(res)) {
		message.success('Bạn đã đăng ký thành công!');
		setAuthCookie(res.data!);
		setApiAuthorization(res?.data?.token);
	}

	message.error(res.data?.error?.message ?? 'Tạo tài khoản thất bại!');
};
