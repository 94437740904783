import React, {memo} from "react";

import {getShortName} from "@/utils";

interface IProps {
    firstName: string;
    lastName: string;
}
const Avatar = ({firstName, lastName}: IProps) => {
    return (
        <>
            <div
                className={
                    'w-8 h-8 rounded-full bg-blue-2 row justify-center text-white font-semibold'
                }
            >
                {getShortName(firstName, lastName)}
            </div>
            <p
                className={
                    'flex-1 text-sm truncate px-2 font-semibold text-blue-2'
                }
            >
                {firstName +
                    ' ' +
                    lastName}
            </p>
        </>
    );
};

export default memo(Avatar);
