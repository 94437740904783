import React from 'react';
import { IoChevronForward } from 'react-icons/io5';

import { NextImage, SmartLink } from '@/app/_components/index';
import { ROUTERS } from '@/constant';

import AuthButton from './_component/AuthButton';
import FooterForm from './Footer.form';

const Footer = async ({ t }: { t: any }) => {
	return (
		<div className={'shadow-default bg-white mt-36'}>
			<div className="lg:flex px-[2rem] md:px-0 container lg:space-x-8">
				<div className="flex flex-col py-10">
					<div
						className={
							'flex md:min-w-[170px] max-w-[180px] max-sm:w-[140px] bg-center bg-cover object-center max-sm:h-auto -ml-1'
						}
					>
						<NextImage
							src={require('@/app/assets/image/logo/logoc4c.png')}
							alt={'LogoCode4Change'}
						/>
					</div>
					<div
						className={
							'block space-y-10 lg:flex lg:space-x-8 lg:space-y-0 mt-4'
						}
					>
						<div className="flex flex-col flex-1 space-y-4">
							<p className={'text-gray-7 font-semibold'}>
								{t('footer.title')}
							</p>
							<p className="text-sm font-normal leading-6 text-gray-7/80">
								{t('footer.content')}
							</p>
							<SmartLink
								href={ROUTERS.ABOUT_US}
								className="font-semibold bg-transparent row text-blue-2 max-w-max"
							>
								{t('footer.btn')}
								<IoChevronForward className={'ml-2'} />
							</SmartLink>
						</div>
						<div className="flex-1 space-y-6 md:flex lg:space-x-3 md:space-y-0">
							<div className={'flex-1 space-y-4'}>
								<p className="font-semibold text-gray-7 whitespace-nowrap">
									{t('footer.link')}
								</p>
								<div className="flex flex-col space-y-2 text-sm font-normal text-gray-7/80">
									<SmartLink href={ROUTERS.ABOUT_US}>
										{t('footer.link.1')}
									</SmartLink>
									<SmartLink href={ROUTERS.PROJECT}>
										{t('footer.link.2')}
									</SmartLink>
									<SmartLink href={ROUTERS.BLOG}>
										{t('footer.link.3')}
									</SmartLink>
									<SmartLink href={ROUTERS.COURSE}>
										{t('footer.link.4')}
									</SmartLink>
									<AuthButton />
								</div>
							</div>
							<div className={'flex-[2.5] space-y-4'}>
								<p className="font-semibold text-gray-7">
									{t('footer.location')}
								</p>
								<div className="flex flex-col space-y-2 text-sm font-normal text-gray-7/80">
									<SmartLink
										href={`https://maps.google.com/maps?q=15 Ung Văn Khiêm, Đà Nẵng, Việt Nam`}
										target="_blank"
									>
										<p>Address: {t('location.address')}</p>
									</SmartLink>
									<SmartLink
										href={'mailto:support@code4change.dev'}
									>
										<p>Mail: support@code4change.dev</p>
									</SmartLink>
									<SmartLink href={'tel:+84706145483'}>
										<p>VN Phone: + 84 706 145 483</p>
									</SmartLink>
								</div>
								<div className={'space-x-4'}>
									<i
										className={
											'c4c-linkdln text-gray-7/80 text-xl cursor-pointer'
										}
									/>
									<i
										className={
											'c4c-facebook text-gray-7/80 text-xl cursor-pointer'
										}
									/>
									<i
										className={
											'c4c-instagram text-gray-7/80 text-xl cursor-pointer'
										}
									/>
									<i
										className={
											'c4c-youtube text-gray-7/80 text-xl cursor-pointer'
										}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FooterForm />
			</div>
			<div className={'flex bg-gradient-default min-h-[60px] py-2'}>
				<div
					className={'px-[2rem] md:px-0 container row justify-center'}
				>
					<span className="font-normal text-white max-md:text-sm">
						© 2023 Code4Change. All rights reserved.
					</span>
				</div>
			</div>
		</div>
	);
};

export default Footer;
