import {memo} from "react";

import classNames from "classnames";

interface IProps {
    className?: string;
    label?: string;
    onSubmit?: any;
    loading?: boolean;
    disabled?: boolean;
}

const SubmitBtn = ({className, label, onSubmit, disabled, loading}: IProps) => {
    return <button
        aria-hidden='true'
        onClick={onSubmit}
        disabled={disabled}
        className={
            classNames(
                'rounded-default btn-shadow row justify-center px-4 py-2 active:translate-y-[1px]',
                `${disabled ? 'opacity-75' : 'opacity-100'}`,
                className
            )}>
        {loading && <div className={'border-2 border-white border-b-transparent w-4 h-4 rounded-full animate-spin mr-2 opacity-75'}/>}
        {label}
    </button>;
};

export default memo(SubmitBtn);
