'use client';
import React, { memo, useState } from 'react';
import { IoCaretForwardOutline, IoPlayOutline } from 'react-icons/io5';

import { EnrollmentButton, ExamButton } from '@/app/_components/index';
import { ICourse, ILesson } from '@/services';

interface IProps {
	item: any;
	lessons: [] | any;
	index: number;
	courseData: ICourse;
	slug: string;
}
export const ProgramDetail = memo(
	({ item, lessons, index, courseData, slug }: IProps) => {
		const [showMore, setShowMore] = useState(true);
		return (
			<>
				<div
					onClick={() => setShowMore(!showMore)}
					className={
						'w-full h-[44px] md:px-5 px-2 bg-zinc-300/80 row justify-between cursor-pointer'
					}
				>
					<span
						className={
							'text-base font-bold text-gray-7 line-clamp-1 row flex-1'
						}
					>
						{index + 1}. {item?.name}
					</span>
					<div className={'row space-x-2'}>
						<span className={'text-sm ml-2 text-gray-7'}>
							{lessons?.length} {'bài giảng'}
						</span>
						<IoCaretForwardOutline
							className={`text-[18px] text-gray/60 duration-300 ${
								showMore ? 'rotate-90' : 'rotate-0'
							}`}
						/>
					</div>
				</div>
				<div
					className={'duration-300 overflow-hidden'}
					style={{
						height: showMore
							? item.id === item?.questions?.[0]?.programId
								? 44 * lessons?.length + 44
								: 44 * lessons?.length
							: 0,
					}}
				>
					{lessons?.map?.((item: ILesson) => (
						<div
							key={item?.id}
							className={
								'w-full h-[44px] md:px-5 px-3 hover:bg-blue-2/30 text-gray-7 bg-zinc-300/40 row justify-between duration-200'
							}
						>
							<div className={'space-x-3 row flex-1'}>
								<IoPlayOutline />
								<span
									className={
										'text-sm font-medium row flex-1 line-clamp-2'
									}
								>
									{item?.title}
								</span>
							</div>
							<EnrollmentButton
								courseData={courseData}
								lessonId={Number(item.id)}
								slug={slug}
							>
								{'Bắt đầu'}
							</EnrollmentButton>
						</div>
					))}
					{item.id === item?.questions?.[0]?.programId && (
						<ExamButton examId={Number(item?.id)} />
					)}
				</div>
			</>
		);
	}
);
