import React from 'react';

import { ROUTERS } from '@/constant';
import { ICourse } from '@/services';

import SmartLink from '../SmartLink';

export const EnrollmentButton = ({
	children,
	lessonId,
	slug,
}: {
	courseData: ICourse;
	children: React.ReactNode;
	lessonId?: number;
	colorPrimary?: string;
	slug: string;
}) => {
	const targetPath =
		ROUTERS.LESSON_DETAIL.replace('[slug]', slug as string) +
		(lessonId ? `?lessonId=${lessonId}` : '');

	return (
		<SmartLink href={targetPath}>
			<button className="row !bg-blue-2 rounded-full text-xs text-white px-1 py-1">
				{children}
			</button>
		</SmartLink>
	);
};
