'use client';
import React from 'react';

import { useLang } from '@/hooks';

const CText: React.FC<{ as?: string; content: string; className: string }> = ({
	as,
	content,
	className,
}) => {
	const { t } = useLang();

	if (as === 'h1') {
		return <h1 className={className}>{t(content)}</h1>;
	}

	if (as === 'h2') {
		return <h2 className={className}>{t(content)}</h2>;
	}

	if (as === 'h3') {
		return <h3 className={className}>{t(content)}</h3>;
	}

	if (as === 'span') {
		return <span className={className}>{t(content)}</span>;
	}

	return <p className={className}>{t(content)}</p>;
};
export default CText;
