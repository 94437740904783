import { ROUTERS } from '@/constant';

export const HEADERS = [
	{
		route: ROUTERS.HOME,
		label: 'header.home',
	},
	{
		route: ROUTERS.ABOUT_US,
		label: 'header.about-us',
	},
	{
		route: ROUTERS.PROJECT,
		label: 'header.projects',
	},
	{
		route: ROUTERS.PRODUCT,
		label: 'header.product',
	},
	{
		route: ROUTERS.BLOG,
		label: 'header.blog',
	},
	{
		route: ROUTERS.COURSE,
		label: 'header.course',
	},
];

export const HEADERS_MOBILE = [
	{
		route: ROUTERS.HOME,
		label: 'header.home',
	},
	{
		route: ROUTERS.ABOUT_US,
		label: 'header.about-us',
	},
	{
		route: ROUTERS.PROJECT,
		label: 'header.projects',
	},
	{
		route: ROUTERS.PRODUCT,
		label: 'header.product',
	},
	{
		route: ROUTERS.BLOG,
		label: 'header.blog',
	},
	{
		route: ROUTERS.COURSE,
		label: 'header.course',
	},
];

export const ADMIN = 'admin',
	EXPERT = 'expert',
	MODERATOR = 'moderator';

export const TRANSLATE = [
	{
		id: 1,
		label: 'vi',
		url: require('./vietnam.png'),
	},
	{
		id: 2,
		label: 'en',
		url: require('./english.png'),
	},
];
