'use client';
import { memo } from 'react';
import Link, { LinkProps } from 'next/link';
import { useParams } from 'next/navigation';

import { insertObjectIf } from '@/utils';

import { isString } from 'ramda-adjunct';

interface ISmartLinkProps extends Omit<LinkProps, 'href'> {
	href: string;
	className?: string;
	target?: string;
	targetBasePath?: string;
	skip?: boolean;
	style?: Object;
	children?: any;
}
const SmartLink = ({
	children,
	href,
	onClick,
	className,
	target,
	style,
	...rest
}: ISmartLinkProps) => {
	const params = useParams();
	const locale = params.locale ? '/' + params.locale : '';

	const _href = isString(href)
		? `${locale}${href}`
		: {
				pathname: `${locale}${href?.[0]}`,
				query: href?.[1],
		  };
	return (
		<Link
			href={_href}
			onClick={onClick}
			className={className}
			target={target}
			style={style}
			{...insertObjectIf(target === '_blank', {
				rel: 'noopener noreferrer',
			})}
			{...rest}
		>
			{children}
		</Link>
	);
};

export default memo(SmartLink);
