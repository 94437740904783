'use client';

import React, { memo, useCallback } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

const Search = () => {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { push } = useRouter();

	const defaultSearch = searchParams.get('q')?.toString();
	const onSearch = useCallback(
		(event: any) => {
			if (event.key === 'Enter') {
				const search = event?.target?.value;

				const params = new URLSearchParams(searchParams);
				params.set('q', search);
				params.set('page', '1');
				push(`${pathname}?${params.toString()}`);
			}
		},
		[pathname]
	);
	return (
		<div className="row mb-6 h-9 bg-white shadow max-w-[300px] rounded-md overflow-hidden">
			<IoSearchOutline className={'text-blue-2 text-[18px] ml-[8px]'} />
			<input
				onKeyDown={onSearch}
				type="text"
				placeholder="Search blog by key word..."
				className={
					'text-sm px-2 bg-white flex-1 h-full outline-none w-[200px] focus:w-[300px] hover:w-[300px] duration-500'
				}
				defaultValue={defaultSearch}
			/>
		</div>
	);
};

export default memo(Search);
