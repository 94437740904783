import {memo} from "react";

const Tag = memo(({tags}: {tags: any}) => {
    const tagName = tags?.split(', ');
    return tagName.map((item: any, index: number) => <div key={index.toString()} className={'py-1 px-2 text-xs text-blue-9 bg-blue-4/40 rounded cursor-pointer truncate'}>
        {item}
    </div>);
});

export default Tag;
